.navbar {
    display: flex;
    flex-direction: row;
    background-color: var(--accent-color);
    color: var(--text-secondary);
    align-items: center;
}

.navbar-icons > * {
    cursor: pointer;
}

.navbar-ext-icon {
    width: 1.2em;
    height: 1.2em;
    margin-left: 0.3em;
}

.navbar-icons img {
    height: 2.5rem;
    aspect-ratio: 1/1;
    margin: 0.5rem;
    user-select: none;
    display: flex;
}

.navbar-icons svg {
    margin: 0.7rem;
}

.navbar-links {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.navbar-links a {
    text-decoration: none;
    background-color: #f2f2f2;
    padding: 1rem 2rem;
    font-weight: bold;
    color: inherit;
    background-color: var(--accent-color);
    display: flex;
    align-items: center;
}

.navbar-links a:hover {
    filter: brightness(0.9);
}

.hamburger-menu {
    display: none;
}

@media only screen and (max-width: 900px) {
    .navbar {
        flex-direction: column;
        overflow: hidden;
    }

    .navbar-links {
        display: flex;
        text-align: center;
        flex-direction: column;
        height: 0px;
        transition: height 0.3s ease-in-out;
        width: 100%;
    }

    .navbar-links a {
        width: 100%;
        padding: 1rem 0px;
    }

    .active {
        height: 21rem;
    }

    .hamburger-menu {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
    }
}