.hero-container {
    position: relative;
}

.hero-container h1 {
    font-size: max(40pt, 6vw);
}

.hero-container p {
    font-size: max(20pt, 1.6vw);
}

.hero-img {
    height: auto;
    width: 100%;
    max-height: calc(100vh - 3.5rem); /* 3.5rem is the height of the navbar */
    object-fit: cover;
    filter: brightness(0.6); 
    user-select: none;
}

.hero-overlay {
    text-align: center;
    position: absolute;
    color: var(--text-secondary);
    top: 45%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
}

@media (max-width: 900px) {
    .hero-container p {
        font-size: 0px !important;
    }
}