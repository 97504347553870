.mini {
  height: 0.5rem;
  width: 0.25rem;
}

.small {
  height: 1rem;
  width: 0.5rem;
}

.medium {
  height: 3rem;
  width: 1.5rem;
}

.large {
  height: 6rem;
  width: 3rem;
}
