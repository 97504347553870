.album-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.album-image {
    position: relative;
}

.album-image img {
    width: 100%;
}

.album-image .text-container {
    position: absolute;
    left: 0;
    bottom: 0;
    padding-top: 3rem;
    height: 20%;
    width: 100%;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
}

.album-image span {
    position: absolute;
    bottom: 0;
    font-style: italic;
    color: rgba(255, 255, 255, 0.9);
    padding: 0.5rem;
}

@media only screen and (max-width: 768px) {
    .album-container {
        grid-template-columns: 1fr;
    }

    .album-image {
        margin: 0 2rem;  /* squeeze closer to center */
    } 
}